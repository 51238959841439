import React from 'react';
import { TimelineLite } from "gsap/all";
import DesktopWindow from '../generic/DesktopWindow';
// import FormInfo from './FormInfo.js';
import GenericForm from './GenericForm.js';

export default class Form extends React.Component {
  constructor(props){
    super(props);

    // this.state = {
    //   formRequestName: "I Need A Website / App",
    // }

    this.closeForms = this.closeForms.bind(this);
    // this.openContactForm = this.openContactForm.bind(this);
    // this.closeContactForm = this.closeContactForm.bind(this);
  }

  closeForms() {
    new TimelineLite()
    .to(`#contact`, 0.1, { display: 'none' });
  }

  // openContactForm(form) {
  //   this.setState({formRequestName: form});
  //
  //   new TimelineLite()
  //   .to(`#contact-form`, 0.5, { display: 'block' })
  // }
  //
  // closeContactForm()  {
  //   new TimelineLite()
  //   .to(`#contact-form`, 0.1, { display: 'none' });
  // }

  render() {
    return (
      <div>
        {/*<DesktopWindow id="contact" close={this.closeForms}>
          <FormInfo openContactForm={this.openContactForm} />
        </DesktopWindow>*/}

        {/*<DesktopWindow id="contact-form" close={this.closeContactForm}>
          <GenericForm />
        </DesktopWindow>*/}

        <DesktopWindow zIndex={this.props.zIndex} id="contact" close={this.closeForms}>
          <GenericForm />
        </DesktopWindow>
      </div>
    );
  }
}

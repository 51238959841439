import React from 'react';
import $ from 'jquery';
import { TimelineLite } from "gsap/all";
import Draggable from 'react-draggable';
import styled from 'styled-components';

import {
  folder,
  folderImage
} from '../styles.js';

const FolderBox = styled.div`${folder}`;
const FolderImage = styled.div`${folderImage}`;


export default class Folder extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      onSingleClickState: this.props.selectedIcon,
      didDrag: false,
      didClick: false,
      isOpen: false
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleSingleClick = this.handleSingleClick.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
     this.wrapperRef = node;
   }

   handleClickOutside(event) {
     if (this.props.shouldSelect) {
       if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
         new TimelineLite()
         .set($("#" + this.props.folderName), {backgroundImage: `url(${this.props.folderIcon})`})
         .set($("#" + this.props.folderName + "-text"), {backgroundColor: 'rgba(0,0,0,0)'});

         this.setState({didClick: false});

       } else if (event.target.id === "#projects-close-icon") {
         new TimelineLite()
         .set($("#" + this.props.folderName), {backgroundImage: `url(${this.props.folderIcon})`})
         .set($("#" + this.props.folderName + "-text"), {backgroundColor: 'rgba(0,0,0,0)'});

         this.setState({didClick: true});

       }
     }

   }

  handleSingleClick(event) {
    if (this.state.didDrag) {
      this.setState({didDrag: false});

    } else {

      if (!this.state.didClick) {
        this.setState({didClick: true});

        if (this.props.shouldSelect) {
          new TimelineLite({
            onComplete: function() {
              this.setState({isOpen: true})
              this.props.openFolder(this.props.folderName);
            }.bind(this)
          })
          .set($("#" + this.props.folderName), {backgroundImage: `url(${this.props.selectedIcon})`})
          .set($("#" + this.props.folderName + "-text"), {backgroundColor: '#41A4E2'});

        } else {
          this.setState({isOpen: true, didClick: false})
          this.props.openFolder(this.props.folderName);
        }
      }

    }
  }

  handleDrag() {
    if (!this.state.didDrag) {
      this.setState({didDrag: true});
    }
  }

  render() {
    var folderName = this.props.folderName;
    if (folderName != null) {
      if (folderName === 'Privacy-Policy') {
        folderName = "Privacy Policy";
      }
    }
    return (
      <Draggable onDrag={this.handleDrag}>
        <FolderBox ref={this.setWrapperRef} className="folder" onClick={this.handleSingleClick} style={{'top': this.props.top, 'left': this.props.left}}>
          <FolderImage id={this.props.folderName} className="folder-image" style={{backgroundImage: `url(${this.props.folderIcon})`}}></FolderImage>
          <div>
            <p id={`${this.props.folderName}-text`}>{folderName}</p>
          </div>
        </FolderBox>
      </Draggable>
    );
  }
}

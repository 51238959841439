import React from 'react';
import { Navbar, Nav }  from 'react-bootstrap';
import { Link }  from 'react-router-dom';
import { TimelineLite } from "gsap/all";

export default class Header extends React.Component {

  openFolder(folderName) {
    new TimelineLite()
    .to(`#${folderName.toLowerCase()}`, 0.5, { display: 'block' });
  }

  render() {
    return (
      <Navbar expand="lg" fixed="top">
        <Navbar.Brand href="/"  tabIndex="-1">
          BISCUIT & CHAI <span className="mobile-navbar-brand">| <span className="mobile-lte-brand">LTE</span></span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Link className="nav-link" onClick={() => this.openFolder('Projects')} to="/projects">Projects</Link>
            <Link className="nav-link" onClick={() => this.openFolder('Blog')} to="/blog">Blog</Link>
            {/*<Nav.Link><Link onClick={() => this.openFolder('Pricing')} to="/pricing">Pricing</Link></Nav.Link>*/}
            <Link className="nav-link" onClick={() => this.openFolder('Contact')} to="/contact">Contact</Link>
            <Link className="nav-link" onClick={() => this.openFolder('Privacy-Policy')} to="/privacy-policy">Privacy Policy</Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

import { css } from 'styled-components';

export const folder = css`
  height: 80px;
  width: 80px;
  position: fixed;
  cursor: pointer;
  /* display: none; */

  p {
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 500;

    @media (max-width: 991px) {
      padding-top: 10px;
    }
  }

  input {
    @media (max-width: 991px) {
      margin-top: 10px;
    }
  }

  @media (max-width: 991px) {
    height: 70px;
    width: 70px;
  }
`;

export const folderImage = css`
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

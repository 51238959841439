import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export default class ImageCarousel extends React.Component {

  createCarousel = () => {
    let carousel = []

    for (let i = 0; i < this.props.projectImages.length; i++) {
      carousel.push(<div key={i}><img src={this.props.projectImages[i]} alt={`project-${i}`} /></div>);
    }

    return carousel
  }

  render() {
    return (
      <Carousel className="dont-drag" emulateTouch infiniteLoop showArrows={false} showIndicators={false} showStatus={false} onClickThumb={this.props.clickThumbnail} selectedItem={this.props.selectedItem} >
        {this.createCarousel()}
      </Carousel>
    )
  }
}

import React from 'react';
import CloseBar from '../CloseBar.js';
import Draggable from 'react-draggable';
import styled from 'styled-components';

import {
  screenWindow,
  mainWindow
} from './styles.js';

const ScreenWindow = styled.section`${screenWindow}`;
const MainWindow = styled.div`${mainWindow}`;

export default class DesktopWindow extends React.Component {
  render() {
    return (
      <Draggable cancel={'.dont-drag'}>
        <ScreenWindow id={`${this.props.id}`} style={{zIndex: `${this.props.zIndex}`}}>
          <CloseBar close={this.props.close} />
          <MainWindow>
            {this.props.children}
          </MainWindow>
        </ScreenWindow>
      </Draggable>
    );
  }
}

import React from 'react';
import { TimelineLite } from "gsap/all";
import DesktopWindow from '../generic/DesktopWindow';
import SocialInfo from './SocialInfo.js';

export default class Social extends React.Component {
  constructor(props){
    super(props);

    this.closeSocial = this.closeSocial.bind(this);
  }

  closeSocial() {
    new TimelineLite()
    .to(`#social`, 0.1, { display: 'none' });
  }

  render() {
    return (
      <div>
        <DesktopWindow zIndex={this.props.zIndex} id="social" close={this.closeSocial}>
          <SocialInfo />
        </DesktopWindow>
      </div>
    );
  }
}

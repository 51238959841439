import React from 'react';

export default class ProjectInfo extends React.Component {
  render() {
    return (
      <div className="project-information">
        {/*<h1 onClick={() => this.props.openProject(this.props.project)}>{this.props.project.name}</h1>*/}
        <h1>{this.props.project.name}</h1>
        <div>
          {this.props.project.stack.split(', ').map(function(s, i) {
            return <h4 key={i}>{ s }</h4>;
          })}
        </div>
        <br/>
        <h3>
          {this.props.project.background}
        </h3>
        <br/>
        {/*this.props.project.url !== null ?
          (<h5 onClick={() => this.props.openProject(this.props.project)} style={{cursor: 'pointer'}}>
            View The Website
          </h5>)
          : null
        */}
        <p onClick={() => this.props.nextProject(this.props.projectIdx)} style={{position: 'absolute', right: '20px', bottom: '0', cursor: 'pointer'}}>Next</p>
      </div>
    );
  }
}

import React from 'react';
import styled from 'styled-components';

import {
  screenWindow,
  mainWindow
} from './styles.js';

const ScreenWindow = styled.section`${screenWindow}`;
const MainWindow = styled.div`${mainWindow}`;

export default class MobileWindow extends React.Component {
  render() {
    return (
      <ScreenWindow id={`${this.props.id}`}>
        <MainWindow>
          {this.props.children}
        </MainWindow>
      </ScreenWindow>
    );
  }
}

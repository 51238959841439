import React, { Component } from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import Header from './Home/components/generic/Header';
import IndexPage from './Home/containers/IndexPage';


class Routes extends Component {

	render(){
		return <div>
			<BrowserRouter>
				<div>

					{/* MENU */}
          <Header />

					{/* CONTENT */}
					<Route path="*" exact>
						{ ({ match }) => <IndexPage show={match !== null} endpt={match.url} /> }
					</Route>

				</div>
			</BrowserRouter>

		</div>;
	}

}

export default Routes;

import React from 'react';
import { TimelineLite } from "gsap/all";
import MobileWindow from '../../generic/MobileWindow';
import GenericForm from '../GenericForm.js';

export default class Form extends React.Component {
  constructor(props){
    super(props);

    this.closeForms = this.closeForms.bind(this);
  }

  closeForms() {
    new TimelineLite()
    .to(`#contact`, 0.1, { display: 'none' });
  }

  render() {
    return (
      <div>
        <MobileWindow id="contact" close={this.closeForms}>
          <GenericForm />
        </MobileWindow>
      </div>
    );
  }
}

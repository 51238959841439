import React from 'react';
import CloseBar from '../generic/CloseBar.js';
import Iframe from 'react-iframe'
import Draggable from 'react-draggable';

export default class Browser extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      isDragging: false,
      noPointerEventsClass: false
    }

    this.isDragging = this.isDragging.bind(this);
    this.stopDragging = this.stopDragging.bind(this);
  }

  isDragging() {
    if (!this.state.isDragging) {
      this.setState({isDragging: true, noPointerEventsClass: true});
    }
  }

  stopDragging() {
    if (this.state.isDragging) {
      this.setState({isDragging: false, noPointerEventsClass: false});
    }

  }

  render() {
    return (
      <Draggable onDrag={this.isDragging} onStop={this.stopDragging}>
        <section className="projectIframeSection">
          <CloseBar close={this.props.closeIframe} />
          <Iframe url={this.props.selected.url}
            id="projectIframe"
            display="initial"
            className={this.state.noPointerEventsClass ? 'no-pointer-events' : null}
            allowFullScreen/>
        </section>
      </Draggable>
    );
  }
}

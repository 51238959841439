import React from 'react';
import { Link }  from 'react-router-dom';

const closeIcon = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/general/closeicon.png'

export default class CloseBar extends React.Component {
  render() {
    return (
      <div style={{width: '100%', height:'25px', backgroundColor:'#2C2D2F'}}>
        <div style={{marginLeft: '10px'}}>
          <Link to="/">
            <img id="projects-close-icon" className="close-icon" onClick={this.props.close} src={closeIcon} alt="close-icon"/>
          </Link>
        </div>
      </div>
    )
  }
}

import { css } from 'styled-components';

export const screenWindow = css`
  position: absolute;
  top: 60px;
  left:0;
  right:0;
  bottom:0;
  width: 70vw;
  height: 75vh;
  margin:0 auto;
  display: none;

  @media (max-width: 991px) {
    width: 95vw;
    top: 0px;
    height: 80%;
    border-top: 10px solid white;
    border-bottom: 10px solid white;
    z-index: 1031;
  }
`;

export const mainWindow = css`
  background-color: #1F2022;
  height: 100%;
  width: 100%;
  display: inline-block;
  float: right;
  padding: 20px;
  overflow: scroll;
  text-align: center;
`;

import React from 'react';
import {Col, Row} from 'react-bootstrap';
import $ from 'jquery';
import axios from 'axios';

// import web from "../../../images/web.png";
// import webselected from "../../../images/web-selected.png";
// import backend from "../../../images/backend.png"
// import backendselected from "../../../images/backend-selected.png"
// import iphone from "../../../images/iphone.png"
// import iphoneselected from "../../../images/iphone-selected.png"
// import android from "../../../images/android.png"
// import androidselected from "../../../images/android-selected.png"

const logo = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/general/logo.png';
const web = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/forms/web.png"
const webselected = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/forms/web-selected.png"
const backend = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/forms/backend.png"
const backendselected = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/forms/backend-selected.png"
const iphone = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/forms/iphone.png"
const iphoneselected = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/forms/iphone-selected.png"
const android = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/forms/android.png"
const androidselected = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/forms/android-selected.png"

export default class GenericForm extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      setWeb: web,
      setIPhone: iphone,
      setAndroid: android,
      setBackend: backend,
      submitted: false
    }

    this.handleClick = this.handleClick.bind(this);
    this.handleTextClick = this.handleTextClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleTextClick(e) {
    if (e.target.id === "tsw") {
      if (this.state.setWeb === web) {
        this.setState({setWeb: webselected});
      } else {
        this.setState({setWeb: web});
      }

    } else if (e.target.id === "tsi") {
      if (this.state.setIPhone === iphone) {
        this.setState({setIPhone: iphoneselected});
      } else {
        this.setState({setIPhone: iphone});
      }

    } else if (e.target.id === "tsa") {
      if (this.state.setAndroid === android) {
        this.setState({setAndroid: androidselected});
      } else {
        this.setState({setAndroid: android});
      }

    } else if (e.target.id === "tsb") {
      if (this.state.setBackend === backend) {
        this.setState({setBackend: backendselected});
      } else {
        this.setState({setBackend: backend});
      }
    }
  }

  handleClick(e) {
    if (e.target.id === "tech-select-web") {
      if (this.state.setWeb === web) {
        this.setState({setWeb: webselected});
      } else {
        this.setState({setWeb: web});
      }

    } else if (e.target.id === "tech-select-iphone") {
      if (this.state.setIPhone === iphone) {
        this.setState({setIPhone: iphoneselected});
      } else {
        this.setState({setIPhone: iphone});
      }

    } else if (e.target.id === "tech-select-android") {
      if (this.state.setAndroid === android) {
        this.setState({setAndroid: androidselected});
      } else {
        this.setState({setAndroid: android});
      }

    } else if (e.target.id === "tech-select-backend") {
      if (this.state.setBackend === backend) {
        this.setState({setBackend: backendselected});
      } else {
        this.setState({setBackend: backend});
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    if ($("input[name=name]").val() === '') {
      console.log('empty name');
      return;
    }
    if ($("input[name=email]").val() === '') {
      console.log('empty email');
      return;
    }

    let backendSelected = (this.state.setBackend === backendselected);
    let webSelected = (this.state.setWeb === webselected);
    let iphoneSelected = (this.state.setIPhone === iphoneselected);
    let androidSelected = (this.state.setAndroid === androidselected);

    var data = {
      iphone: iphoneSelected,
      android: androidSelected,
      web: webSelected,
      backend: backendSelected,
      name: $("input[name=name]").val(),
      email: $("input[name=email]").val(),
      phone: $("input[name=phone]").val(),
      company: $("input[name=company_name]").val(),
      details: $("textarea").val()
    }

    axios.post('/api/contactform', data)
    .then(({ data }) => {
        if (data === 'OK' ) {
          this.setState({submitted: true});
        }
      });

  }

  render() {
    if (this.state.submitted === true) {
      return (
        <div className="form-details">
          <h3>Message Sent - We'll Reach Out To You Soon!</h3>
          <Row style={{marginTop: '20px'}}>
            <Col lg={3} md={3} sm={3} xs={3}>
              <center>
                <img className="tech-select" src={logo} alt="form-logo" />
              </center>
            </Col>
          </Row>
        </div>

      )
    }

    return (
          <div className="form-details">
            <h3>Select Your Requirements</h3>
            <form className="dont-drag">
              <div className="form-group">
              <Row>
              <Col lg={3} md={3} sm={3} xs={3}>
                  <div className="ts">
                    <img id="tech-select-web" onClick={this.handleClick} className="tech-select" src={this.state.setWeb} alt="form-web" />
                    <h5 id="tsw" onClick={this.handleTextClick}>Website</h5>
                  </div>
                </Col>

                <Col lg={3} md={3} sm={3} xs={3}>
                  <div className="ts">
                    <img id="tech-select-iphone" onClick={this.handleClick} className="tech-select" src={this.state.setIPhone} alt="form-iphone"/>
                    <h5 id="tsi" onClick={this.handleTextClick}>iPhone</h5>
                  </div>
                </Col>

                <Col lg={3} md={3} sm={3} xs={3}>
                  <div className="ts">
                    <img id="tech-select-android" onClick={this.handleClick} className="tech-select" src={this.state.setAndroid} alt="form-android"/>
                    <h5 id="tsa" onClick={this.handleTextClick}>Android</h5>
                  </div>
                </Col>

                <Col lg={3} md={3} sm={3} xs={3}>
                  <div className="ts">
                    <img id="tech-select-backend" onClick={this.handleClick} className="tech-select" src={this.state.setBackend} alt="form-backend"/>
                    <h5 id="tsb" onClick={this.handleTextClick}>Backend</h5>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <input className="form-control" type="text" placeholder="Name *" name="name" />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <input className="form-control" type="text" placeholder="Company Name" name="company_name" />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <input className="form-control" type="email" placeholder="E-mail *" name="email" />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <input className="form-control" type="email" placeholder="Phone Number" name="phone" />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <textarea className="form-control" rows="5" placeholder="Project Details / Other Information" name="details"></textarea>
                </Col>
              </Row>
              </div>
              <center><button className="btn btn-primary" onClick={this.handleSubmit}>Let's Get Started</button></center>
            </form>
          </div>
    );
  }
}

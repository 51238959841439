import React from 'react';
// import axios from 'axios';
import {Col, Row} from 'react-bootstrap';

const blog1 = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/blog/posts/blog1.gif';
const blog2 = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/blog/posts/blog2.png';

export default class BlogInfo extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      posts: []
    }
  }

  componentDidMount() {
    // axios.get('/api/retrieve_blog')
    //   .then((result) => {
    //     console.log(result);
    //   });
  }

  render() {
    return (
      <div>
        <h1 style={{textAlign: 'center'}}>Bit && Sip Blog</h1>
        <h5>

        </h5>
        <br/>
        <Row>
          {/*this.state.posts.map(function(s, i) {
            return (
              <Col lg={3} md={6} sm={12}>
                <div className="blog-thumb">
                  <img src={blogsample} />
                </div>
              </Col>
            );
          })*/}
          <Col lg={3} md={6} sm={12}>
            {/*<div onClick={() => this.props.openBlog("blog url")} className="blog-thumb">*/}
            <a style={{color: 'white', textDecoration: 'none'}} rel="noopener noreferrer" href="https://medium.com/@biscuitandchai/create-a-website-that-tells-a-story-dc8f2cbbecf9" target="_blank">
              <div className="blog-thumb">
                <img src={blog1} alt="Create A Website That Tells a Story" />
                <p>Create a Website That Tells a Story</p>
              </div>
            </a>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <a style={{color: 'white', textDecoration: 'none'}} rel="noopener noreferrer" href="https://medium.com/@biscuitandchai/the-budget-love-triangle-a72f6d785b5f" target="_blank">
              <div className="blog-thumb">
                <img src={blog2} alt="The Budget Love Triangle" />
                <p>The Budget Love Triangle</p>
              </div>
            </a>
          </Col>
        </Row>
      </div>
    );
  }
}

import React from 'react';
import { TimelineLite } from "gsap/all";
import DesktopWindow from '../generic/DesktopWindow';
import BlogInfo from './BlogInfo.js';
// import BlogPost from './BlogPost.js';
// import blog1 from '../../../images/blogs/blog1.gif';

export default class Blog extends React.Component {
  constructor(props){
    super(props);

    // this.state = {
    //   blogs: blogs,
    //   showFrame: false,
    //   selectedBlog: null
    // }

    // this.openBlogFrame = this.openBlogFrame.bind(this);
    // this.closeFrame = this.closeFrame.bind(this);
    this.closeBlog = this.closeBlog.bind(this);
  }

  // openBlogFrame(blog) {
  //   //the blog parameter should equal an index to match to this.state.blogs - it doesn't right now
  //   this.setState({showFrame: true, selectedBlog: this.state.blogs[0]});
  //
  //   new TimelineLite()
  //   .to(`.blog-frameholder`, 0.5, { display: 'block' })
  //   .to(`.blog-frameholder`, 0.5, { opacity: 1 });
  // }
  //
  // closeFrame()  {
  //   this.setState({showFrame: false, selectedBlog: null})
  // }

  closeBlog() {
    new TimelineLite()
    .to(`#blog`, 0.1, { display: 'none' });
  }

  render() {
    return (
      <div>
        <DesktopWindow zIndex={this.props.zIndex} id="blog" close={this.closeBlog}>
          <BlogInfo /> {/*openBlog={this.openBlog}*/}
        </DesktopWindow>

        {/*<div className="blog-frameholder">
        {this.state.showFrame ?
          (
            <BlogPost selected={this.state.selectedBlog} closeFrame={this.closeFrame} />
          ) : null
        }
        </div>*/}

      </div>
    );
  }
}

import React from 'react';
import { TimelineLite } from "gsap/all";
import Carousel from '../generic/Carousel.js';
import DesktopWindow from '../generic/DesktopWindow';
import BrowserIframe from '../generic/BrowserIframe.js';
import ProjectInfo from './ProjectInfo.js';

const neurala = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/clients/neurala.png'
const bards = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/clients/bards.png'
const bosco = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/clients/thebosco.png'
const ycb = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/clients/ycb.png'
const cgp = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/clients/cgpclothing.png'
const sirclepos = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/clients/sirclepos.png'
const deane = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/clients/deane.png'
const buffalo = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/clients/buffalo.png'
const chaipos = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/clients/chaipos.png'
const premjyotish = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/clients/premjyotish.png'
const trimed = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/clients/trimed.png'
const panoply = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/clients/panoply.png'
const theplaylist = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/clients/theplaylist.png'
const wellnessbiscuit = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/clients/wellnessbiscuit.png'
const groupie = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/clients/groupie.png'
const sirca = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/clients/sirca.png'
const swad = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/clients/swad.png'

export default class Project extends React.Component {
  constructor(props){
    super(props);

    var projects = [
      {
        name: 'Sirca',
        // url: 'https://sircanav.com',
        url: null,
        background: "A mobile navigation app catered to exploring destinations along your current route.",
        stack: "Mobile: Objective-C + Swift (iOS) + Java (Android), Web: Ruby",
        details: "",
      },
      {
        name: 'Swad',
        // url: 'https://sircanav.com',
        url: null,
        background: "Largest South Asian food brand in the U.S. looked to create a branding website and mobile application.",
        stack: "Web: NodeJS + React, Mobile: React Native",
        details: "",
      },
      {
        name: 'Neurala',
        url: null,
        // url: 'https://neurala.com',
        background: "An AI solution platform required updates to their marketing website.",
        stack: "Web: HubSpot",
        details: "",
      },
      {
        name: 'Bards of Nevermore',
        // url: 'https://bardsofnevermore.com',
        url: null,
        background: "A creative ad agency looked to rebrand and build their website.",
        stack: "Web: HTML + CSS + JavaScript",
        details: "",
      },
      {
        name: 'The Bosco',
        url: null,
        // url: 'https://thebosco.co',
        background: "A social media photo and gif experience agency looked to update and enhance their iPad photobooth experience.",
        stack: "Mobile: Objective-C (iOS Only)",
        details: "",
      },
      {
        name: 'Yoga Club Boston',
        url: null,
        // url: 'https://yogaclubboston.com',
        background: "A small yoga studio looked to build out a new workout video content platform for online subscribers.",
        stack: "Web: Node.JS + React",
        details: "",
      },
      {
        name: 'CGP Clothing',
        url: null,
        // url: 'https://itunes.apple.com/us/app/cgp-clothing/id1144697587?mt=8',
        background: "MMA-inspired clothing brand looked to create a mobile e-commerce application.",
        stack: "Mobile: Swift (iOS Only)",
        details: "",
      },
      {
        name: 'SirclePOS',
        url: null,
        // url: 'https://www.sirclepos.com/backoffice-ios-app',
        background: "Android-based POS system looked to create a companion iOS application to handle administrative POS tasks.",
        stack: "Mobile: Swift (iOS Only)",
        details: "",
      },
      {
        name: 'Deane Corporate Finance',
        url: null,
        // url: 'https://deanecorporatefinance.com',
        background: "Financial consulting firm looked to rebrand and build their website.",
        stack: "Web: Wordpress",
        details: "",
      },
      {
        name: 'Buffalo & Associates',
        url: null,
        // url: 'https://vbalaw.com/',
        background: "Law firm looked to rebrand and build their website.",
        stack: "Web: HTML + CSS + JavaScript",
        details: "",
      },
      {
        name: 'Prem Jyotish',
        url: 'https://premjyotish.net',
        background: "Astrologist and numerologist looked to rebrand and rebuild the website.",
        stack: "Web: HTML + CSS + JavaScript",
        details: "",
      },
      {
        name: 'TriMed',
        url: null,
        // url: 'https://trimedhomecare.com'
        background: "Elder home care service provider looked to create platform to provide information to families.",
        stack: "Web: Laravel, Mobile: Java (Android) + Objective-C (iOS)",
        details: "",
      },
      {
        name: 'Panoply By Sakshi',
        url: 'https://panoplybysakshi.com',
        background: "Custom jeweler looked to brand and create the website.",
        stack: "Web: HTML + CSS + JavaScript",
        details: "",
      },
      {
        name: 'ChaiPOS',
        url: 'https://chaipos.com',
        background: "In-house project: POS system currently under closed beta testing.",
        stack: "Web: Node.JS + React, Mobile: Swift + Objective-C (iOS) + Java (Android)",
        details: "",
      },
      {
        name: 'thePlaylist',
        url: 'https://realtheplaylist.com',
        background: "In-house project: Bi-weekly music app for iOS devices.",
        stack: "Mobile: Swift (iOS Only)",
        details: "",
      },
      {
        name: 'Wellness Biscuit',
        url: 'https://wellnessbiscuit.com',
        background: "In-house project: Website and brand templates for fitness professionals and studios.",
        stack: "Web: HTML + CSS + JavaScript",
        details: "",
      },
      {
        name: 'Groupie.io',
        url: 'https://groupie.io',
        background: "In-house project: Fan engagement mobile app for artists.",
        stack: "Web: NodeJS, Mobile: React Native",
        details: "",
      },
    ]

    this.state = {
      allProjects: projects,
      currentProjectName: 'Sirca',
      selectedItem: 0,
      showIframe: false,
      selectedProject: null,
    };

    this.clickThumbnail = this.clickThumbnail.bind(this);
    this.openProject = this.openProject.bind(this);
    this.nextProject = this.nextProject.bind(this);
    this.closeIframe = this.closeIframe.bind(this);
    this.closeProject = this.closeProject.bind(this);
  }

  clickThumbnail(e) {
    this.setState({selectedItem: e});
  }

  openProject(project) {
    this.setState({showIframe: true, selectedProject: project});

    new TimelineLite()
    .to(`.frameholder`, 0.5, { display: 'block' })
    .to(`.frameholder`, 0.5, { opacity: 1 });
  }


  nextProject(projectIdx) {
    if (this.state.allProjects.length > projectIdx+1) {
      this.setState({selectedItem: projectIdx+1});

    } else {
      this.setState({selectedItem: 0});
    }
  }

  closeIframe()  {
    this.setState({showIframe: false, selectedProject: null})
  }

  closeProject() {
    new TimelineLite()
    .to(`#projects`, 0.1, { display: 'none' });
  }


  render() {
    let projectImages = [sirca, swad, neurala, bards, bosco, ycb, cgp, sirclepos, deane, buffalo, premjyotish, trimed, panoply, chaipos, theplaylist, wellnessbiscuit, groupie]

    return (
      <div>
        <DesktopWindow zIndex={this.props.zIndex} id="projects" close={this.closeProject}>
          <ProjectInfo project={this.state.allProjects[this.state.selectedItem]} projectIdx={this.state.selectedItem} nextProject={this.nextProject} openProject={this.openProject} />
          <Carousel projectImages={projectImages} clickThumbnail={this.clickThumbnail} selectedItem={this.state.selectedItem} />
        </DesktopWindow>

        <div className="frameholder">
        {this.state.showIframe ?
          (
            <BrowserIframe selected={this.state.selectedProject} closeIframe={this.closeIframe} />
          ) : null
        }
        </div>
      </div>
    );
  }
}

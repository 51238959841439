import React, { Component } from 'react';
import { Transition } from "react-transition-group";
import { TweenMax } from "gsap/all";

import Home from '../components/home/HomeHeader';
import Project from '../components/projects/Project.js';
import MobileProject from '../components/projects/mobile/Project.js';
import Blog from '../components/blog/Blog.js';
import MobileBlog from '../components/blog/mobile/Blog.js';
import Form from '../components/forms/Form.js';
import MobileForm from '../components/forms/mobile/Form.js';
import Social from '../components/social/Social.js';
import PrivacyPolicy from '../components/policies/Policy.js';
import MobilePrivacyPolicy from '../components/policies/mobile/Policy.js';

const startState = { autoAlpha: 0, y: 400 };

class IndexPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: null,
      height: null
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount(){
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }


  render() {
    if (this.state.width == null) {
      return (<div></div>);
    }

    return (
      <Transition
      	unmountOnExit
      	in={this.props.show}
      	timeout={1000}
      	onEnter={node => TweenMax.set(node, startState)}
      	addEndListener={ (node, done) => {
      		TweenMax.to(node, 0.5, {
      			autoAlpha: this.props.show ? 1 : 0,
      			y: this.props.show ? 0 : 50,
      			onComplete: done
      		});
      	}}>
        <div>
          <Home endpt={this.props.endpt} mobile={this.state.width < 991 ? true : false} />
          {this.state.width < 991 ? (<MobileProject zIndex={this.props.endpt === '/projects' ? 28 : 27} />) : (<Project zIndex={this.props.endpt === '/projects' ? 28 : 27} />)}
          {this.state.width < 991 ? (<MobileBlog zIndex={this.props.endpt === '/blog' ? 28 : 27} />) : (<Blog zIndex={this.props.endpt === '/blog' ? 28 : 27} />)}
          {this.state.width < 991 ? (<MobileForm zIndex={this.props.endpt === '/contact' ? 28 : 27} />) : (<Form zIndex={this.props.endpt === '/contact' ? 28 : 27} />)}
          <Social zIndex={this.props.endpt === '/social' ? 28 : 27} />
          {this.state.width < 991 ? (<MobilePrivacyPolicy zIndex={this.props.endpt === '/privacy-policy' ? 28 : 27} />) : (<PrivacyPolicy zIndex={this.props.endpt === '/privacy-policy' ? 28 : 27} />)}
        </div>
      </Transition>
    );
  }
}

export default IndexPage;

import React from 'react';
import {Col, Row} from 'react-bootstrap';

const instagram = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/social/social-instagram.png"
const linkedin = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/social/social-linkedin.png"
const email = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/social/social-email.png"

export default class SocialInfo extends React.Component {
  render() {
    return (
      <div className="form-details">
        <h3>Social</h3>
        <Row style={{marginTop: '80px'}}>
          <Col lg={4} md={4} sm={4} xs={4}>
            <div className="ts" onClick={()=> window.open("https://www.instagram.com/biscuit.chai/", "_blank")}>
              <img className="social-select" src={instagram} alt="instagram" />
              <h5>Instagram</h5>
            </div>
          </Col>

          <Col lg={4} md={4} sm={4} xs={4}>
          <div className="ts" onClick={()=> window.open("https://www.linkedin.com/company/biscuit-&-chai", "_blank")}>
            <img className="social-select" src={linkedin} alt="linkedin" />
            <h5>LinkedIn</h5>
          </div>
          </Col>

          <Col lg={4} md={4} sm={4} xs={4}>
            <div className="ts" onClick={()=> window.open("mailto:apps@biscuitandchai.com", "_self")}>
              <img className="social-select" src={email} alt="email" />
              <h5 style={{fontSize: '16px'}}>apps@biscuitandchai.com</h5>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import Routes from './App';
import { CookiesProvider } from 'react-cookie';

render((
  <CookiesProvider>
  <BrowserRouter>
    <Routes />
  </BrowserRouter>
  </CookiesProvider>
), document.getElementById('root'));

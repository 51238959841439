import React from "react";
import { TimelineLite } from "gsap/all";
import { Row } from 'react-bootstrap';
import Vivus from 'vivus';
import $ from 'jquery';

export default class Desktop extends React.Component {

  componentDidMount() {
    $("#computer-row").animate({'opacity': '1'});

    new Vivus('computer', {duration: 100}, function() {
      new TimelineLite()
      .to(['#svg_15', '#svg_26'], 0.5, {opacity: 1});

    })
  }

  componentWillMount() {
    $("#svg_15").animate({'opacity': '0'});
    $("#svg_26").animate({'opacity': '0'});
  }

  render() {
    return (
      <Row id="computer-row">
        <h2 id="skip-btn" onClick={this.props.skipStartup}>SKIP</h2>
        <svg id="computer" width='255' height='456' xmlns='http://www.w3.org/2000/svg'>
          <rect x='-1' y='-1' width='257' height='458' id='canvas_background' fill="rgba(0,0,0,0)" />
          <g id='canvasGrid' display='none'>
              <rect id='svg_2' width='100%' height='100%' strokeWidth='0' fill='url(#gridpattern)'
              />
          </g>
          <g>
              <rect strokeWidth='10' x='10.5' y='7' width='235' height='436' id='svg_1'
              stroke='#fff' fillOpacity='1' fill="#000" />
              <ellipse strokeWidth='10' cx='125.048' cy='252.691' id='svg_4' rx='3.976'
              ry='3.976' stroke='#fff' />
              <line fill='none' strokeWidth='20' x1='124.952' y1='39.037' x2='124.952'
              y2='178.096' id='svg_6' strokeLinejoin='null' strokeLinecap='null' stroke='#fff'
              />
              <ellipse  id='svg_8' cy='198.438' cx='797' strokeWidth='10' stroke='#fff'
              fill='#fff' />
              <a href="#start" onClick={this.props.handleTurnOn}>
                <ellipse ry='28' rx='28' id='svg_14' cy='306.938' cx='124.5' strokeWidth='4'
                stroke='#fff' />
              </a>
              <a href="#start" onClick={this.props.handleTurnOn}>
                <ellipse stroke='#fff' ry='16' rx='16' id='svg_9' cy='306.573' cx='124.5'
                strokeWidth='4' />
              </a>
              <a href="#start" onClick={this.props.handleTurnOn}>
                <rect stroke='#000' id='svg_15' height='20' width='9' y='283.438' x='120'
                strokeWidth='4' fill='#fff' style={{opacity: '0'}} />
              </a>
              <a href="#start" onClick={this.props.handleTurnOn}>
                <ellipse transform='rotate(32.717 131.88 311.494)' ry='6.606' rx='2.139'
                id='svg_26' cy='311.494' cx='131.88' strokeWidth='4' fill='#fff' style={{opacity: '0'}} />
              </a>
          </g>
        </svg>
      </Row>
    );
  }
};

import React from 'react';
import { TimelineLite } from "gsap/all";
import BlogInfo from './BlogInfo.js';
import MobileWindow from '../../generic/MobileWindow';

export default class Blog extends React.Component {
  constructor(props){
    super(props);

    this.closeBlog = this.closeBlog.bind(this);
  }

  closeBlog() {
    new TimelineLite()
    .to(`#blog`, 0.1, { display: 'none' });
  }

  render() {
    return (
      <MobileWindow id="blog" close={this.closeBlog}>
        <BlogInfo />
      </MobileWindow>
    );
  }
}

import React from "react";
import { Row } from 'react-bootstrap';
import { TimelineMax } from "gsap/all";
import $ from 'jquery';

export default class DesktopStarted extends React.Component {
  
  componentDidMount() {
    $('#computer-row').css('left', '90%');
    $("#computer-row").animate({'opacity': '1'});

    new TimelineMax({repeat: -1})
    .to("#svg_4", 0.2, { fill: 'black' })
    .to("#svg_4", 0.2, { fill: 'white' });
  }


  render() {
    return (
      <Row id="computer-row">
        <svg id='computer' width='255' height='456' xmlns='http://www.w3.org/2000/svg'>
          <path id='canvas_background' fill='rgba(0,0,0,0)' d='M-1 -1 L256 -1 L256 457 L-1 457 Z'
          data-svg-origin='-1 -1' transform='translate(0 346)' strokeDasharray='1430,1432'
          />
          <g id='canvasGrid' display='none'>
              <path id='svg_2' strokeWidth='0' fill='url(#gridpattern)' d='M0 0 L100 0 L100 100 L0 100 Z'
              strokeDasharray='400,402' />
          </g>
          <path strokeWidth='10' id='svg_1' stroke='#fff' d='M10.5 7 L245.5 7 L245.5 443 L10.5 443 Z'
          data-svg-origin='10.5 7' transform='translate(0 348)' strokeDasharray='1342,1344'
          />
          <path strokeWidth='5' id='svg_4' stroke='#b5b7bb' d='M121.072,252.691A3.976,3.976 0,1,1 129.024,252.691A3.976,3.976 0,1,1 121.072,252.691'
          data-svg-origin='121.07199096679688 248.71498107910156' transform='translate(-60 158)'
          strokeDasharray='25,27' fill='#f6f6f6' />
          <path fill='none' strokeWidth='20' id='svg_6' strokeLinejoin='null' strokeLinecap='null'
          stroke='#fff' d='M124.952,39.037L124.952,178.096' data-svg-origin='124.9520034790039 39.0369987487793'
          transform='translate(0 458)' strokeDasharray='140,142' />
          <path id='svg_8' strokeWidth='10' stroke='#fff' fill='#fff' d='M797,198.438A0,0 0,1,1 797,198.438A0,0 0,1,1 797,198.438'
          data-svg-origin='797 198.43800354003906' transform='translate(0 108)' strokeDasharray='0,2'
          /><path id='svg_14' strokeWidth='4' stroke='#fff' d='M96.5,306.938A28,28 0,1,1 152.5,306.938A28,28 0,1,1 96.5,306.938' data-svg-origin='96.5 278.93798828125' transform='translate(0 108)' strokeDasharray='176,178'/><path stroke='#fff' id='svg_9' strokeWidth='4' d='M108.5,306.573A16,16 0,1,1 140.5,306.573A16,16 0,1,1 108.5,306.573' data-svg-origin='108.5 290.572998046875' transform='translate(0 108)' strokeDasharray='101,103'/><path stroke='#000' id='svg_15' strokeWidth='4' fill='#fff' d='M120 283.438 L129 283.438 L129 303.438 L120 303.438 Z' data-svg-origin='120 283.43798828125' transform='translate(0 108)' strokeDasharray='58,60'/><path transform='rotate(32.717 -52.272 365.548)' id='svg_26' strokeWidth='4' fill='#fff' d='M129.74099999999999,311.494A2.139,6.606 0,1,1 134.019,311.494A2.139,6.606 0,1,1 129.74099999999999,311.494' data-svg-origin='129.74099731445312 304.88800048828125' strokeDasharray='30,32'/>
        </svg>
      </Row>
    )
  }
};

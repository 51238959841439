import React from 'react';
import {withRouter} from 'react-router-dom'
import $ from 'jquery';
import {Container, Row} from 'react-bootstrap';
import { TimelineLite, TimelineMax } from "gsap/all";
// import Vivus from 'vivus';
import Typed from 'typed.js';
import { withCookies } from 'react-cookie';

import Mobile from '../generic/devices/mobile/Mobile.js';
import Desktop from '../generic/devices/desktop/InitialDesktop.js';
import DesktopStarted from '../generic/devices/desktop/StartedDesktop.js';
import Folder from '../folder/DesktopFolder';
// import Custom from '../customproject/CustomProject.js';
// import EditableFolder from '../folder/EditableDesktopFolder';

import {
  isAndroid
} from "react-device-detect";

import CSSPlugin from 'gsap/CSSPlugin';
const C = CSSPlugin;

const mainbg = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/general/background2.jpg'
const mainbg2 = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/general/bg5.jpg'
const mainbg3 = 'https://biscuitandchai-general.nyc3.digitaloceanspaces.com/bcassets/general/background.jpg'
const mainbg4 = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/general/bg3.jpeg'
const mainbg5 = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/general/bg4.jpg'
const phonescreen = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/general/phonescreen.gif'
const rotatedphonescreen = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/general/rotated-phonescreen.gif'
const mainLogoIcon = 'https://biscuitandchai-general.nyc3.digitaloceanspaces.com/bcassets/general/logo.png'

// const folderIcon = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/customproject/macfolder.png'
// const selectedFolder = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/customproject/macfolder-selected.png'
// const emptyIOS = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/customproject/empty-ios.png'

const genericFolderIcon = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/general/folder.png'
const selectedGenericFolder = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/general/folder-selected.png'
const genericWindowsFolderIcon = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/general/windows-folder.png'
const selectedGenericWindowsFolder = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/general/windows-folder-selected.png'

var folderIcon = genericFolderIcon
var selectedFolder = selectedGenericFolder

const formsIcon = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/forms/formsicon.png"
const selectedForms = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/forms/formsicon-selected.png"
const formsMobile = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/forms/formsicon-mobile.png"

const blogIcon = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/blog/blogicon.png"
const selectedBlog = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/blog/blogicon-selected.png"
const blogIOS = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/blog/blog-ios.png"
const blogAndroid = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/blog/blog-android.png"

const projectIcon = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/projecticon.png"
const selectedProject = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/projecticon-selected.png"
const projectIOS = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/projects-ios.png"
const projectAndroid = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/projects/projects-android.png"

const socialIcon = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/social/socialicon.png"
const selectedSocial = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/social/socialicon-selected.png"
const socialEmailIOS = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/social/contact-ios.png"
const socialEmailAndroid = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/social/contact-android.png"
const socialInstagram = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/social/instagram.png"
const socialLinkedinIOS = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/social/linkedin-ios.png'
const socialLinkedinAndroid = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/social/linkedin-android.png'

const privacyIcon = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/policies/ppicon.png"
const selectedPrivacy = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/policies/ppicon-selected.png"
const privacyIOS = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/policies/privacy-ios.png"
const privacyAndroid = "https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/policies/policies/privacy-android.png"

class Home extends React.Component {
  constructor(props){
    super(props);

    const { cookies } = props;

    this.state = {
      startUp: cookies.get('bc-start-up') || 0,
      finishStartAnimation: false,
      showStarted: false,
      allowBackspace: false,
      startCustomText: false,
      mkdirText: 'mkdir my-project',
      startText: 'Starting Up...',
      folderIsOpen: false,
      formIsOpen: false,
      // customFolders: cookies.get('bc-project-folders') || [],
      // customFolders: cookies.get('test-bc-project-folders') || [],
      // selectedCustomFolder: null,
    };

    this.handleTurnOn = this.handleTurnOn.bind(this);
    // this.handleTurnOnMobile = this.handleTurnOnMobile.bind(this);
    this.mobileHomeClick = this.mobileHomeClick.bind(this);
    this.openFolder = this.openFolder.bind(this);
    this.skipStartup = this.skipStartup.bind(this);
    this.saveCustomFolderName = this.saveCustomFolderName.bind(this);
    this.skipAll = this.skipAll.bind(this);
  }

  componentDidMount() {
    // const { cookies } = this.props;

    if (this.state.startUp == 1 || this.props.mobile === true) {

      if (this.props.mobile == true) {
        this.setState({showStarted: true});

        new TimelineLite()
        .to(".intro-title", 0.1, {opacity: '0'})
        .to(".intro-title", 0.1, {display: 'none'})
        .to("#home", 0.1, {zIndex: 'unset'})
        .to("#home", 0.1, {opacity: 0})
        .to(".folder", 0.1, {display: 'block'})
        .to("#home", 0.1, {opacity: 1});

      } else {
        $("#start-cursor").animate({'opacity': '1'});

        this.setState({allowBackspace: true, startText: "Restarting System...", showStarted: true});

        const { cookies } = this.props;
        var currentBg = mainbg;
        if (cookies.get('current-bc-bg')) {
          currentBg = cookies.get('current-bc-bg');

          if (currentBg == mainbg2) {
            folderIcon = genericWindowsFolderIcon;
            selectedFolder = selectedGenericWindowsFolder;
          } else {
            folderIcon = genericFolderIcon;
            selectedFolder = selectedGenericFolder;
          }

        } else {
          cookies.set('current-bc-bg', currentBg, { path: '/' });
        }

        new TimelineLite()
        .to(".intro-title", 0.1, {opacity: '0'})
        .to(".intro-title", 0.1, {display: 'none'})
        .to("#home", 0.1, {zIndex: 'unset'})
        .to("#home", 0.1, {opacity: 0})
        .to("#skip-btn", 0.1, {display: `none`})
        .to(".folder", 0.1, {display: 'block'})
        .set("#home", {backgroundImage: `url(${currentBg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: `center`})
        .to("#home", 0.1, {opacity: 1});

        this.typed = new Typed("#typed-strings", {
          strings: ["Brewing <span style='color: #fcb400;'>Thoughtfully Designed Products.</span>"],
          typeSpeed: 50,
          cursorChar: '_',
        });
      }

      if (this.props.endpt == "/projects") {
        new TimelineLite()
        .to(`#projects`, 0.5, { display: 'block' });

      } else if (this.props.endpt == '/blog') {
        new TimelineLite()
        .to(`#blog`, 0.5, { display: 'block' });

      } else if (this.props.endpt == '/contact') {
        new TimelineLite()
        .to(`#contact`, 0.5, { display: 'block' });
        // .to(`#contact-form`, 0.5, { display: 'block' });

        this.setState({formIsOpen: true});

      } else if (this.props.endpt == '/forms') {
        new TimelineLite()
        .to(`#contact`, 0.5, { display: 'block' });

        this.setState({formIsOpen: true});

      } else if (this.props.endpt == '/social') {
        new TimelineLite()
        .to(`#social`, 0.5, { display: 'block' });

        this.setState({formIsOpen: true});

      } else if (this.props.endpt.includes('my-project-')) {
        new TimelineLite()
        .to(`#${this.props.endpt.substr(1)}`, 0.5, { display: 'block' });

      } else if (this.props.endpt == '/privacy-policy') {
        new TimelineLite()
        .to(`#privacy-policy`, 0.5, { display: 'block' });

      }

    }

    document.addEventListener("keydown", this._handleKeyDown);
	}

  componentWillUnmount() {
    if (this.typed) {
      this.typed.destroy();
    }
    document.removeEventListener("keydown", this._handleKeyDown);
  }

  skipAll() {
    $("#start-cursor").animate({'opacity': '1'});

    const { cookies } = this.props;

    cookies.set('bc-start-up', 1, { path: '/' });

    this.setState({allowBackspace: true, startText: "Restarting System...", showStarted: true, startUp: 1});

    new TimelineLite()
    .to(".intro-title", 0.1, {opacity: '0'})
    .to(".intro-title", 0.1, {display: 'none'})
    .to("#home", 0.1, {zIndex: 'unset'})
    .to("#home", 0.1, {opacity: 0})
    .to("#skip-btn", 0.1, {display: `none`})
    .to(".folder", 0.1, {display: 'block'})
    .set("#home", {backgroundImage: `url(${mainbg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: `center`})
    .to("#home", 0.1, {opacity: 1});

    this.typed = new Typed("#typed-strings", {
      strings: ["Brewing <span style='color: #fcb400;'>Thoughtfully Designed Products.</span>"],
      typeSpeed: 50,
      cursorChar: '_',
    });
  }

  skipStartup() {
    this.typed.stop();

    this.typed.destroy();

    this.typed = new Typed("#typed-strings", {
      strings: ["Brewing <span style='color: #fcb400;'>Thoughtfully Designed Products.</span>"],
      typeSpeed: 50,
      cursorChar: '_',
    });

    new TimelineLite({onComplete: function() {
      this.setState({allowBackspace: true, startText: "Restarting System...", finishStartAnimation: true});
    }.bind(this) })
    .to("#home", 0.5, {zIndex: 'unset'})
    .to("#home", 0.5, {opacity: 0})
    .to("#skip-btn", 0.1, {display: `none`})
    .to(".folder", 0.1, {display: 'block'})
    .set("#home", {backgroundImage: `url(${mainbg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: `center`})
    .to("#home", 0.5, {opacity: 1});

  }

  // handleTurnOnMobile() {
  //   if (this.state.startUp == 1) {
  //     return
  //   }
  //
  //   const { cookies } = this.props;
  //   cookies.set('bc-start-up', 1, { path: '/' });
  //   this.setState({startUp: 1});
  //
  //   $('#mobile-main').animate(
  //     {'top': '80vh'},
  //     function() {
  //
  //     });
  //
  // }

  mobileHomeClick() {
    this.props.history.push(`/`)

    new TimelineLite()
    .to('#blog', 0.2, { opacity: '0' })
    .to('#projects', 0.2, { opacity: '0' })
    .to('#contact', 0.2, { opacity: '0' })
    .to('#social', 0.2, { opacity: '0' })
    .to('#privacy-policy', 0.2, { opacity: '0' })
    .to('#blog', 0.1, { display: 'none' })
    .to('#projects', 0.1, { display: 'none' })
    .to('#contact', 0.1, { display: 'none' })
    .to('#social', 0.1, { display: 'none' })
    .to('#privacy-policy', 0.2, { display: 'none' })
    .to('#blog', 0.1, { opacity: '1' })
    .to('#projects', 0.1, { opacity: '1' })
    .to('#contact', 0.1, { opacity: '1' })
    .to('#social', 0.1, { opacity: '1' })
    .to('#privacy-policy', 0.1, { opacity: '1' })
  }

  handleTurnOn() {
    if (this.state.startUp == 1) {
      return
    }

    const { cookies } = this.props;
    cookies.set('bc-start-up', 1, { path: '/' });
    this.setState({startUp: 1});

    new TimelineLite({onComplete: function() {
      $('#computer-row').animate(
        {'left': '90%'},
        function() {
          new TimelineMax({repeat: -1})
          .to("#svg_4", 0.2, { fill: 'black' })
          .to("#svg_4", 0.2, { fill: 'white' });

          new TimelineLite()
          .to("#svg_1", 0.5, { y: '348px' });

          new TimelineLite()
          .to("#canvas_background", 0.5, { y: '346px' });

          new TimelineLite()
          .to("#svg_6", 0.5, { y: '458px' });

          new TimelineLite()
          .to("#svg_4", 0.5, { y: '158px', x: '-60px' })

          new TimelineLite({
            onComplete: function() {

               new TimelineLite({
                onComplete: function() {
                  new TimelineLite()
                  .to("#skip-btn", 0.2, {display: `block`});

                  $("#start-cursor").animate({'opacity': '1'});

                  this.typed = new Typed("#typed-strings", {
                    strings: ["", this.state.startText, "Brewing <span style='color: #fcb400;'>Great User Experiences.</span>", "Brewing <span style='color: #fcb400;'>Beautiful Designs.</span>", "Brewing <span style='color: #fcb400;'>Mobile + Web Apps.</span>", "Brewing <span style='color: #fcb400;'>Thoughtfully Designed Products.</span>"],
                    typeSpeed: 50,
                    cursorChar: '_',
                    onStringTyped: (arrayPos, self) => {
                      if (arrayPos == 1) {
                        new TimelineLite()
                        .to("#home", 0.1, {background: `black`})
                        .set($("#home"), {backgroundImage: `url(${phonescreen})`})
                        .to("#home", 0.2, {backgroundPosition: `center`});

                      } else if (arrayPos == 2) {
                        new TimelineLite()
                        .to("#home", 0.2, {backgroundPosition: `right`});

                      } else if (arrayPos == 3) {
                        new TimelineLite()
                        .set($("#home"), {backgroundImage: `url(${rotatedphonescreen})`})
                        .to("#home", 0.2, {backgroundRepeat: `repeat`})
                        .to("#home", 0.2, {backgroundPosition: `center`});

                      } else if (arrayPos == 4) {
                        new TimelineLite()
                        .set($("#home"), {backgroundImage: `url(${phonescreen})`});

                      } else if (arrayPos == 5) {

                        new TimelineLite({onComplete: function() {
                          this.setState({allowBackspace: true, startText: "Restarting System...", finishStartAnimation: true});
                        }.bind(this) })
                        .to("#home", 0.5, {zIndex: 'unset'})
                        .to("#home", 0.5, {opacity: 0})
                        .to("#skip-btn", 0.1, {display: `none`})
                        .to(".folder", 0.1, {display: 'block'})
                        .set("#home", {backgroundImage: `url(${mainbg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: `center`})
                        .to("#home", 0.5, {opacity: 1});
                      }
                    }
                  });
                }.bind(this)
              })
              .set($("#home"), {backgroundImage: `url(${phonescreen})`});
            }.bind(this)
          })
          .staggerTo(["#svg_8", "#svg_9", "#svg_14", "#svg_15", "#svg_26"], 0.5, { y: '108px' });
        }.bind(this));

    }.bind(this)})
    // .staggerTo(["#svg_14", "#svg_9", "#svg_26", "#svg_15"], 0.2, {  x: '-5px',  y: '+5px' })
    // .staggerTo(["#svg_14", "#svg_9", "#svg_26", "#svg_15"], 0.2, {  x: '+0px',  y: '-0px' })
    .to(".intro-title", 0.1, {opacity: '0'})
    .to(".intro-title", 0.1, {display: 'none'})
    .to("#svg_9", 0.2, {  strokeWidth: '8', stroke: 'rgb(255,255,255,0.6)' })
    .to("#svg_9", 0.2, {  strokeWidth: '4', stroke: 'white' })
    .to("#svg_4", 0.2, { stroke: "yellow" })
    .to("#svg_4", 0.2, { stroke: "white" })
    .to("#svg_4", 0.2, { stroke: "yellow" })
    .to("#svg_4", 0.2, { stroke: "white" })
    .to("#svg_4", 0.2, { stroke: "yellow" })
    .to("#svg_4", 0.2, { stroke: "white" })
    .to("#svg_4", 0.5, { stroke: "rgb(181,183,187,1)", fill: 'white' })
    .to("#svg_4", 0.2, { strokeWidth: '5' });
  }


  _handleKeyDown = (event) => {
    if (!this.state.formIsOpen) {

      if (event.keyCode == 8) {
        if (this.state.allowBackspace) {
          $("#typed-strings").text($("#typed-strings").text().slice(0, $("#typed-strings").text().length-1))
        }

      } else if ((event.keyCode >= 65 && event.keyCode <= 90) || event.keyCode == 32) { //32 is the space key, 65 - 90 is letters
        if ($("#typed-strings").text().length == 0 && this.state.allowBackspace) {
          this.setState({startCustomText: true});

          // if ($("#typed-strings").text().length < this.state.mkdirText.length) {
            $("#typed-strings").text($("#typed-strings").text() + event.key);
          // }

        } else if (this.state.startCustomText) {
          $("#typed-strings").text($("#typed-strings").text() + event.key);

          // if ($("#typed-strings").text().length < this.state.mkdirText.length) {
          //   $("#typed-strings").text(this.state.mkdirText.slice(0, $("#typed-strings").text().length+1))
          // }

        }

      } else if (event.keyCode == 13) { //enter key is pressed
        if ($("#typed-strings").text().toLowerCase().includes('cd project') || $("#typed-strings").text().toLowerCase().includes('open project')) {
          this.openFolder('projects');
          $("#typed-strings").text("");

        } else if ($("#typed-strings").text().toLowerCase().includes('cd forms') || $("#typed-strings").text().toLowerCase().includes('cd contact') || $("#typed-strings").text().toLowerCase().includes('open forms') || $("#typed-strings").text().toLowerCase().includes('open contacts')) {
          this.openFolder('contact');
          $("#typed-strings").text("");

        } else if ($("#typed-strings").text().toLowerCase().includes('cd social') || $("#typed-strings").text().toLowerCase().includes('open social')) {
          this.openFolder('social');
          $("#typed-strings").text("");

        } else if ($("#typed-strings").text().toLowerCase().includes('cd blog') || $("#typed-strings").text().toLowerCase().includes('open blog')) {
          this.openFolder('blog');
          $("#typed-strings").text("");

        } else if ($("#typed-strings").text().toLowerCase().includes('change bg') || $("#typed-strings").text().toLowerCase().includes('change theme') || $("#typed-strings").text().toLowerCase().includes('change background')) {
          const { cookies } = this.props;

          var currentBg = cookies.get('current-bc-bg');
          if (currentBg == mainbg) {
            currentBg = mainbg2;
            folderIcon = genericWindowsFolderIcon;
            selectedFolder = selectedGenericWindowsFolder;
          } else if (currentBg == mainbg2) {
            currentBg = mainbg3;
            folderIcon = genericFolderIcon;
            selectedFolder = selectedGenericFolder;
          } else if (currentBg == mainbg3) {
            currentBg = mainbg4;
            folderIcon = genericFolderIcon;
            selectedFolder = selectedGenericFolder;
          } else if (currentBg == mainbg4){
            currentBg = mainbg;
            folderIcon = genericFolderIcon;
            selectedFolder = selectedGenericFolder;
          } else {
            currentBg = mainbg2;
            folderIcon = genericFolderIcon;
            selectedFolder = selectedGenericFolder;
          }
          cookies.set('current-bc-bg', currentBg, { path: '/' });

          new TimelineLite()
          .to("#home", 0.2, {opacity: 0})
          .set("#home", {backgroundImage: `url(${currentBg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: `center`})
          .to("#home", 0.2, {opacity: 1});

          $("#typed-strings").text("");

        } else if ($("#typed-strings").text().toLowerCase().includes('instagram')) {
          window.open("https://www.instagram.com/biscuit.chai/", "_blank");

        } else if ($("#typed-strings").text().toLowerCase().includes('linkedin')) {
          window.open("https://www.linkedin.com/company/biscuit-&-chai/", "_blank");

        } else if ($("#typed-strings").text().toLowerCase().includes('email')) {
          window.open("mailto:apps@biscuitandchai.com", "_self");

        }
        // else if ($("#typed-strings").text().toLowerCase().includes('change folder')) {
        //     if (folderIcon == genericFolderIcon) {
        //       folderIcon = genericWindowsFolderIcon;
        //       selectedFolder = selectedGenericWindowsFolder;
        //     } else {
        //       folderIcon = genericFolderIcon;
        //       selectedFolder = selectedGenericFolder;
        //     }
        //
        //     new TimelineLite()
        //     .to("#home", 0.2, {opacity: 0})
        //     .set('.folder-image', {backgroundImage: `url(${folderIcon})`})
        //     .to("#home", 0.2, {opacity: 1});
        //
        //     $("#typed-strings").text("");
        //
        // }
      }
    }
  }

  openFolder(folderName) {
    if (typeof(folderName) == "string") {
      this.props.history.push(`/${folderName.toLowerCase()}`)

      new TimelineLite()
      .to(`#${folderName.toLowerCase()}`, 0.5, { display: 'block' });

    } else {
      let selected = this.state.customFolders.filter(function(f, i) {
          return f.id == folderName
      })[0];
      this.setState({selectedCustomFolder: selected});

      this.props.history.push(`/my-project-${folderName}`); //folderName == custom folder id
      new TimelineLite()
      .to(`#my-project-${folderName}`, 0.5, { display: 'block' });
    }
  }

  saveCustomFolderName(folderID, folderName) {
    this.state.customFolders.map(function(f, i) {
      if (f.id == folderID) {
        f.folderName = folderName;
      }

      return f;
    });

    this.setState({customFolders: this.state.customFolders});

    const { cookies } = this.props;

    var allCustomFolders = cookies.get('test-bc-project-folders');
    allCustomFolders.map(function(f, i) {
      if (f.id == folderID) {
        f.folderName = folderName;
      }

      return f;
    });
    cookies.set('test-bc-project-folders', allCustomFolders, { path: '/' });

  }

  render() {
    if (this.props.endpt == '/contact' || this.props.endpt == '/forms') {
      if (!this.state.formIsOpen) {
        this.setState({formIsOpen: true});
      }
    } else {
      if (this.state.formIsOpen) {
        this.setState({formIsOpen: false});
      }
    }

    return (
      <section id="home">
        <h1 className="intro-title">
          Biscuit & Chai
          <span>(Click the Power Symbol)</span>
        </h1>
        <Container className="main">
	  <h1 onClick={this.skipAll} className="intro-title">
	    <span style={{textAlign: 'right', cursor: "pointer", textDecoration: "underline", fontSize: '14px'}}>(Skip to Main Screen)<img src={mainLogoIcon} style={{width: '40px', paddingLeft: '10px'}} /></span>
	  </h1>
          {!this.props.mobile ?
            (
              this.state.showStarted == 1 || this.state.finishStartAnimation ?
              (<DesktopStarted />)
              :
              (<Desktop handleTurnOn={this.handleTurnOn.bind(this)} skipStartup={this.skipStartup.bind(this)} />)
            )
            :
            (<Mobile mobileHomeClick={this.mobileHomeClick} />)

          }

          {this.state.finishStartAnimation || this.state.showStarted || this.props.mobile ?
            (
              <Row>
                <Folder folderName={"Projects"} shouldSelect={!this.props.mobile} folderIcon={this.props.mobile ? (isAndroid ? projectAndroid : projectIOS) : folderIcon} selectedIcon={selectedFolder} top={this.props.mobile ? '15vh' : '10vh'} left={this.props.mobile ? '10vw' : '90vw'} openFolder={this.openFolder}/>
                <Folder folderName={"Blog"} shouldSelect={!this.props.mobile} folderIcon={this.props.mobile ? (isAndroid ? blogAndroid : blogIOS) : folderIcon} selectedIcon={selectedFolder} top={this.props.mobile ? '15vh' : '25vh'} left={this.props.mobile ? '40vw' : '90vw'} openFolder={this.openFolder}/>
                <Folder folderName={"Contact"} shouldSelect={!this.props.mobile} folderIcon={this.props.mobile ? formsMobile : folderIcon} selectedIcon={selectedFolder} top={this.props.mobile ? '15vh' : '40vh'}  left={this.props.mobile ? '70vw' : '90vw'} openFolder={this.openFolder} />

                {this.props.mobile ?
                  (
                    <div>
                      <Folder folderName={"Instagram"} shouldSelect={false} folderIcon={socialInstagram} top={'35vh'}  left={'10vw'} openFolder={()=> window.open("https://www.instagram.com/biscuit.chai/", "_blank")} />
                      <Folder folderName={"LinkedIn"} shouldSelect={false} folderIcon={isAndroid ? socialLinkedinAndroid : socialLinkedinIOS} top={'35vh'}  left={'40vw'} openFolder={()=> window.open("https://www.linkedin.com/company/biscuit-&-chai/", "_blank")} />
                      <Folder folderName={"Email"} shouldSelect={false} folderIcon={isAndroid ? socialEmailAndroid : socialEmailIOS} top={'35vh'}  left={'70vw'} openFolder={()=> window.open("mailto:apps@biscuitandchai.com", "_self")} />
                      <Folder folderName={"Privacy-Policy"} shouldSelect={false} folderIcon={isAndroid ? privacyAndroid : privacyIOS} selectedIcon={selectedPrivacy} top={'55vh'} left={'10vw'} openFolder={this.openFolder}/>
                    </div>
                  )
                  :
                  (<Folder folderName={"Social"} shouldSelect={true} folderIcon={folderIcon} selectedIcon={selectedFolder} top={'55vh'}  left={'90vw'} openFolder={this.openFolder} />)
                }

                {/*this.state.customFolders.map(function(folder, i) {
                  return (
                    <EditableFolder key={folder.id} folderIcon={this.props.mobile ? emptyIOS : folderIcon} selectedIcon={selectedfolder} saveCustomFolderName={this.saveCustomFolderName} folderName={folder.folderName} folderID={folder.id} top={this.props.mobile ? '35vh' : '55vh'} left={this.props.mobile ? '40vw' : '20vw'} openFolder={this.openFolder} />
                  );
                }.bind(this))*/}

              </Row>
            )
            : null
          }

        </Container>
        {this.props.mobile ? null :
        (<div id="start-computer">
          <h1 id="start-cursor">{`>`}
          <div id="typed">
            <span id="typed-strings" />
          </div>
          </h1>
        </div>) }

        {/*<Custom folder={this.state.selectedCustomFolder != null ? this.state.selectedCustomFolder : {id: -1}} />*/}
      </section>
    );
  }
}

export default withCookies(withRouter(Home));

import React from "react";
import { Row } from 'react-bootstrap';
import { TimelineMax } from "gsap/all";
import $ from 'jquery';

const logo = 'https://biscuitandchai-general.nyc3.cdn.digitaloceanspaces.com/bcassets/general/logo.png';

export default class Mobile extends React.Component {

  componentDidMount() {
    $('#computer-row').css('left', '90%');
    $("#computer-row").animate({'opacity': '1'});

    new TimelineMax({repeat: -1})
    .to(".mobile-lte-brand", 0.5, { color: 'orange' })
    .to(".mobile-lte-brand", 0.5, { color: 'green' })
    .to(".mobile-lte-brand", 0.5, { color: 'blue' })
    .to(".mobile-lte-brand", 0.5, { color: 'orange' })
  }

  render() {
    return (
      <Row className="mobile-row">
        <div className="mobilelogoholder">
          <img className="mobile-main" src={logo} onClick={this.props.mobileHomeClick} alt="mobile-device" />
        </div>
      </Row>
    )
  }
};
